import React, { useState, useEffect, useRef } from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { useHistory } from 'react-router-dom'
import { CreditCardIcon, CheckCircleIcon, ExclamationIcon, EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/solid'
import { PaidPlanDetails } from '@/pages/SubscriptionForm/PlanDetails'
import Downgrade from '@/pages/SubscriptionForm/Downgrade'
import { Modal, useModal } from '@/shared/Modal'

import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import Loading from '@/shared/Loading'

import { PLANS } from '@/constants/plans'

const initialPlan = (existingSubscription) => {
  if (!Boolean(existingSubscription)) {
    return PLANS[0]
  }

  let thePlan = PLANS.find(plan => plan.plan === existingSubscription.plan)
  if (!thePlan) { thePlan = PLANS[0] }

  return thePlan
}


const ExistingPlan = (props) => {
  const { existingSubscription, setUpdateExisting } = props
  const [, setToast] = useGlobalState('toast')
  const { openModal, closeModal, isOpen } = useModal()
  const [ stripeLoading, setStripeLoading ] = useState(false)
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const { putpostRequest, getRequest } = useQuery()
  const [canceling, setCanceling] = useState(false)
  const [loading, setLoading] = useState(false)
  const planDetails = initialPlan(existingSubscription)
  let history = useHistory()

  const changePaymentMethod = () => {
    setStripeLoading(true)
    putpostRequest(`/api/v1/stripe/change_payment_method`, 'POST', {}, (err, jsonData) => {
      if (err) {
        alert(err)
        return
      }
      window.location.href = jsonData.stripeLink
    })
  }

  const goToMembership = () => {
    setStripeLoading(true)
    openModal()

    const data = {
      mode: 'subscription',
      include_interests: false,
      member: 'member',
      upgrade: 'upgrade',
      yearly: 'yearly'
    }

    putpostRequest(`/api/v1/stripe/build_payment_link`, 'POST', data, (err, jsonData) => {
      if (err) {
        alert(err)
        return
      }
      window.location.href = jsonData.stripeLink
    })
  }

  const toggleCanceling = () => { setCanceling(!canceling) }
  const buyMoreCredits = () => { history.push('/buy_credits') }
  const revertCancel = () => {
    setLoading(true)
    putpostRequest(`/api/v1/subscriptions/revert_cancel`, 'POST', {}, async (err, jsonData) => {
      if (err) { setErrorMessage(err) }
      setLoading(false)
      setToast(<>
        <div className="flex-shrink-0">
          <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Phew! Welcome back!</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You will receive an email confirmation.</p>
        </div>
      </>)
      history.push('/dashboard')
    })
  }
  const cancel = () => {
    setLoading(true)
    putpostRequest(`/api/v1/subscriptions/cancel`, 'POST', {}, async (err, jsonData) => {
      if (err) { setErrorMessage(err) }
      setLoading(false)
      setToast(<>
        <div className="flex-shrink-0">
          <EmojiSadIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Successfully canceled</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You will receive an email confirmation.</p>
        </div>
      </>)
      history.push('/dashboard')
    })
  }

  if (loading) { return <Loading /> }

  if (canceling) {
    return <>
      <div className="rounded-md bg-red-50 p-4 mt-10 mb-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Cancel Subscription</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>Are you sure you want to cancel?  If you cancel, previous charges will not be refunded, but you may continue to use the service until the end of the term on {existingSubscription.expiresAt}.</p>
            </div>
          </div>
        </div>
      </div>
      <DefaultButton onClick={toggleCanceling} text="Back" />
      <DefaultButton className='ml-5' onClick={cancel} text="Yep, go ahead and cancel" />
    </>
  }

  return <>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Loading</h2>
          <Loading noMessage />

          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">Please wait while we setup a checkout session.</p>
        </div>
      </div>
      <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Okay</button>
    </Modal>
    <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div className='col-span-2'>
        <div className='pt-5'>
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">My Current Plan</h3>
        </div>
        { existingSubscription?.downgradeTo && <Downgrade existingSubscription={existingSubscription} /> }
        { existingSubscription?.downgradeTo !== 'cancel' && <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Plan Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">{planDetails.name}</dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Amount Charged On Next Billing Cycle</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">
                {existingSubscription.billingInterval === 'yearly' && <>${planDetails.priceYearly} on {existingSubscription.nextBillingAt}</> }
                {existingSubscription.billingInterval === 'monthly' && <>${planDetails.priceMonthly} on {existingSubscription.nextBillingAt}</> }
              </dd>
            </div>
            { !existingSubscription?.downgradeTo && <>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Subscription Cycle Renews</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">{existingSubscription.expiresAt}</dd>
              </div>
            </> }
          </dl>
        </div> }
        <div className='flex items-center justify-start mt-6'>
          { existingSubscription.status === 'active' && <DefaultButton className='mr-5' onClick={changePaymentMethod} text="Change Payment Method" /> }
          {existingSubscription.downgradeTo !== 'cancel' && <DefaultButton className='mr-5' onClick={toggleCanceling} text="Cancel Subscription" /> }
          {existingSubscription.downgradeTo !== 'cancel' && existingSubscription.billingInterval === 'monthly' && <DefaultButton className='mr-5' onClick={goToMembership} text="Upgrade to Yearly" /> }
          {existingSubscription.downgradeTo === 'cancel' && <PrimaryButton className='mr-5' onClick={revertCancel} text="I Changed My Mind, I Want To Keep My Subscription" /> }
        </div>
      </div>
      <div className='col-span-1 bg-white dark:bg-gray-800 rounded-md text-green-500 border-cccorange dark:border-cccpurple-alt'>
        <PaidPlanDetails />
      </div>
    </div>
  </>
}

export default ExistingPlan
