import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'
import Tippy from '@tippyjs/react'
import ToolTip from '@/shared/ToolTip'
import { EmojiHappyIcon } from '@heroicons/react/solid'

import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'

const PromoCodeForm = () => {
  const [user, setUser] = useGlobalState('currentUser')
  let history = useHistory()
  const { putpostRequest, getRequest } = useQuery()
  const [ customError, setCustomError ] = useState(null)
  const [, setToast] = useGlobalState('toast')
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({ defaultValues: {} })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/promo_codes`, 'POST', { promo_code: data }, (err, jsonData) => {
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }
      if (jsonData.invalidCode) {
        setLoading(false)
        setCustomError(jsonData.invalidCode)
      } else {
        poll(jsonData.id)
      }
    })
  })

  const poll = (promoId) => {
    getRequest(`/api/v1/promo_codes/${promoId}`, {}, (err, jsonData) => {
      if (err) { return }
      console.log(jsonData)
      if (jsonData.promo.result === 'error' && jsonData.promo.error) {
        setLoading(false)
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
          <p className="mt-1 text-sm dark:text-red-300 text-red-400">{jsonData.promo.error}</p>
        </div>)
        setCustomError(jsonData.promo.error)
      } else if (jsonData.promo.result === 'applied' && jsonData.promo.applied === 'ccc_master_plan') {
        setLoading(false)

        setUser({
          ...user,
          access: 'member'
        })

        history.push('/dashboard?promo=true')
      } else {
        setTimeout(() => poll(promoId), 3000)
      }
    })
  }


  return (
    <div className='mt-10'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Promo Code</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              If you have a promo code, please enter it here. Note that some codes take about a minute to process.
            </p>
          </div>
        </div>
        <Card footer={<PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Apply Code" />}>
          <form>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Promo Code</span>

                  <Tippy interactive={true} interactiveBorder={20} delay={100} content={<ToolTip>Required Field</ToolTip>}>
                    <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                  </Tippy>
                </label>
                <div className="mt-1 flex flex-col rounded-md shadow-sm">
                  <div className='relative'>
                    <input autoFocus type="text" className={errors.promo_code ? 'errors' : ''} {...register('promo_code', { required: true }) } placeholder="5up3r5s3cr3t" />
                    { errors.promo_code && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.promo_code && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
                { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default PromoCodeForm
